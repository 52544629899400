<template>

  <div>
    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <span class="text-muted">Showing {{ dataMeta.from }} - {{ dataMeta.to }} of {{ dataMeta.of }}</span>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <a
                href="javascript:void(0)"
                class="nav-link nav-link-search"
                @click="showSearchBar = !showSearchBar"
              >
                <feather-icon
                  icon="SearchIcon"
                  size="21"
                  class="mr-50"
                />
                <span class="align-middle">Search</span>
              </a>
              <b-input-group
                v-if="showSearchBar"
                class="input-group-merge mr-50"
              >
                <b-form-input
                  v-model="searchQuery"
                  placeholder="Search..."
                />
                <b-input-group-append is-text>
                  <feather-icon
                    icon="XIcon"
                    class="cursor-pointer"
                    @click="searchQuery = ''; showSearchBar = !showSearchBar"
                  />
                </b-input-group-append>
              </b-input-group>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refPOTable"
        class="position-relative event_list"
        :class="{ low_items: currentDataLength < 4 }"
        :items="fetchPO"
        responsive
        :fields="tableColumns"
        primary-key="_id"
        :sort-by.sync="sortBy"
        show-empty
        no-sort-reset
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >
        <!-- Column: Role -->
        <template #cell(caseID)="data">
          <div class="">
            <b-button
              v-if="canViewThisAction('show', 'PurchaseRequest')"
              v-ripple.400="'rgba(30, 30, 30, 0.15)'"
              variant="link"
              class="weight-600 align-middle detail-view-id"
              :to="{ name: 'purchasing-purchase-request-show', params: { id: data.item.purchaseRequest._id } }"
            >
              {{ data.item.purchaseRequest.caseID }}
            </b-button>
            <b-button
              v-else
              v-ripple.400="'rgba(30, 30, 30, 0.15)'"
              variant="none"
              class="text-bold-black align-middle detail-view-id not-button"
            >
              {{ data.item.purchaseRequest.caseID }}
            </b-button>
            <br>
            <span style="color: #999999;">{{ data.item.itemsString }}</span>
          </div>
        </template>

        <template #cell(createdAt)="data">
          <div class="text-nowrap">
            <span class="">{{ dateFormat(data.item.createdAt) }}</span>
          </div>
        </template>

        <template #cell(entity)="data">
          <div class="text-nowrap">
            <!-- <span class="">{{ data.item.deliverTo ? data.item.deliverTo.name : '' }}</span> -->
            <span>{{ data.item.purchaseRequest ? (data.item.purchaseRequest.company ? data.item.purchaseRequest.company.name : '') : '' }}</span>
          </div>
        </template>

        <template #cell(requestedBy)="data">
          <div class="">
            <span class="">{{ data.item.purchaseRequest ? (data.item.purchaseRequest.createdBy ? data.item.purchaseRequest.createdBy.name : '') : '' }} ({{ data.item.purchaseRequest ? (data.item.purchaseRequest.createdBy ? data.item.purchaseRequest.createdBy.position : '') : '' }}, {{ data.item.purchaseRequest ? (data.item.purchaseRequest.department ? data.item.purchaseRequest.department.name : '') : '' }})</span>
          </div>
        </template>

        <template #cell(amount)="data">
          <div class="text-nowrap">
            <span class="">{{ numberFormat(data.item.grandTotal) }}</span>
          </div>
        </template>

      </b-table>
    </b-card>

    <div
      v-if="totalPO > perPage"
      class="mx-2 mb-2"
    >
      <b-row>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="12"
          class="d-flex align-items-center justify-content-center pagination-content"
        >

          <b-pagination
            v-model="currentPage"
            :total-rows="totalPO"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            align="center"
            prev-text="Prev"
            next-text="Next"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
              <span>Prev</span>
            </template>
            <template #next-text>
              <span>Next</span>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>

        </b-col>

      </b-row>
    </div>
    <b-modal
      id="modal-update-status-all-items-master-bulk"
      ref="update-status-modal-all-bulk"
      modal-class="status-update-modal"
      cancel-variant="flat-primary"
      ok-variant="success"
      ok-title="Update"
      cancel-title="Cancel"
      centered
      no-close-on-backdrop
      title="Update Status"
      @ok="updateBulkStatus"
    >
      <b-form @submit.prevent="updateBulkStatus">
        <validation-observer
          ref="allPOsBulkStatusEditForm"
        >
          <b-form-group
            label="Status*"
            label-for="h-event-status"
          >
            <validation-provider
              #default="{ errors }"
              name="Status"
              vid="status"
              rules="required"
            >
              <v-select
                id="h-event-status"
                v-model="bulkStatus"
                label="title"
                :options="statusRadioOptions"
                :reduce="title => title.code"
                :clearable="false"
                @input="bulkStatusValidation == true ? bulkStatusValidation = false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
              <small
                v-if="bulkStatusValidation"
                class="text-danger"
              >
                {{ bulkStatusError }}
              </small>
            </validation-provider>
          </b-form-group>
        </validation-observer>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCard, BRow, BButton, BCol, BFormInput, BTable, BInputGroup, BInputGroupAppend, BPagination, BFormGroup, BForm,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ref, onUnmounted, computed } from '@vue/composition-api'
import Ripple from 'vue-ripple-directive'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import { useUtils as useAclUtils } from '@/libs/acl/custom'
import usePOList from './usePOList'
import poStoreModule from './poStoreModule'

export default {
  components: {
    BForm,
    BCard,
    BRow,
    BButton,
    BCol,
    BFormInput,
    BTable,
    BPagination,
    BInputGroup,
    BInputGroupAppend,
    BFormGroup,

    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    vSelect,

    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      bulkStatus: 'published',
      bulkStatusValidation: false,
      bulkStatusError: 'Valid status is required',
      statusRadioOptions: [
        { title: 'Pending', code: 'pending' },
        { title: 'Published', code: 'published' },
        { title: 'Hidden', code: 'hidden' },
      ],
      user: store.state.auth.userData,
      required,
    }
  },

  methods: {
    updateBulkStatus() {
      this.$refs.allPOsBulkStatusEditForm.validate().then(success => {
        if (success) {
          const formData = new FormData()
          formData.append('status', this.bulkStatus)
          // eslint-disable-next-line no-plusplus
          for (let i = 0; i < this.selectedPO.length; i++) {
            formData.append('POs[]', this.selectedPO[i])
          }

          this.$http.patch('purchase/items-master/action/bulk/status/update', formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
          })
            .then(() => {
              this.refetchData()
              this.selectedPO = []
              this.$swal({
                title: 'Status Updated!',
                html: 'The status for selected items master has been updated',
                // eslint-disable-next-line global-require
                imageUrl: require('@/assets/images/icons/save.png'),
                imageWidth: 80,
                imageHeight: 80,
                imageAlt: 'Custom Icon',
                showCancelButton: false,
                confirmButtonText: 'Okay',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            })
            .catch(error => {
              if (error.data.errors) {
                error.data.errors.forEach(validationError => {
                  if (validationError.param === 'bulkStatus') {
                    this.bulkStatusError = validationError.msg
                    this.bulkStatusValidation = true
                  }
                })
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              }
            })
        }
      })
    },
    deleteEntity(id) {
      this.$swal({
        title: 'Warning!',
        text: ' Are you sure you want to delete this?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, Delete!',
        cancelButtonText: 'No, Cancel',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-primary ml-1',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.value) {
            this.toDeletedID = id
            this.deletePO()
          }
        })
    },
  },
  setup() {
    const showSearchBar = ref(false)
    const showFilterOptions = ref(false)
    const ITEMS_MASTER_APP_STORE_MODULE_NAME = 'app-purchase-order'

    // Register module
    if (!store.hasModule(ITEMS_MASTER_APP_STORE_MODULE_NAME)) store.registerModule(ITEMS_MASTER_APP_STORE_MODULE_NAME, poStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(ITEMS_MASTER_APP_STORE_MODULE_NAME)) store.unregisterModule(ITEMS_MASTER_APP_STORE_MODULE_NAME)
    })

    const { canViewThisAction } = useAclUtils()

    const {
      fetchPO,
      tableColumns,
      perPage,
      currentPage,
      POData,
      totalPO,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refPOTable,
      refetchData,

      toDeletedID,
      deletePO,
      currentDataLength,

      // UI
      resolvePOstatusVariant,
    } = usePOList()

    const selectedPO = ref([])
    const toggleSelectedPO = POId => {
      const POIndex = selectedPO.value.indexOf(POId)

      if (POIndex === -1) selectedPO.value.push(POId)
      else selectedPO.value.splice(POIndex, 1)
    }
    const selectAllPOCheckbox = computed(() => POData.value.length && (POData.value.length === selectedPO.value.length))
    const isSelectAllPOCheckboxIndeterminate = computed(() => Boolean(selectedPO.value.length) && POData.value.length !== selectedPO.value.length)
    const selectAllCheckboxUpdate = val => {
      selectedPO.value = val ? POData.value.map(flow => flow._id) : []
    }

    return {

      // Sidebar

      fetchPO,
      tableColumns,
      perPage,
      currentPage,
      totalPO,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refPOTable,
      refetchData,
      showSearchBar,
      showFilterOptions,
      toDeletedID,
      deletePO,
      currentDataLength,

      // Filter
      canViewThisAction,

      // UI
      resolvePOstatusVariant,

      selectAllPOCheckbox,
      isSelectAllPOCheckboxIndeterminate,
      selectedPO,
      toggleSelectedPO,
      selectAllCheckboxUpdate,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
